import { HttpProgressEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FileService {
  /**
   * @author kailash_dev
   * @param uploadingProgress
   * To calculate uploading file to server progress
   * < Write less & code more />
   */
  constructor() { }
  calcProgressPercent(event: HttpProgressEvent){
    return Math.round(100 * event.loaded / event.total);
  }
}
